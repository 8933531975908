'use client';

import React, { useEffect, useLayoutEffect, useState, createRef, useRef } from 'react';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { usePathname } from 'src/hooks/usePathname';
import { classNames } from 'src/utils/css';
import { isEquivalentLocale, setLanguageCookie } from 'src/utils/localization';
import { replaceLocaleInPathname } from 'src/utils/urls';
import { usePageAttributesContext } from 'src/components/contexts/PageAttributesContext';
import { useTranslation } from 'src/components/contexts/TranslationContext';
import { CaretDown } from 'src/components/common/Icons';
import { CTAButton } from 'src/components/common/CTAButton';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';

import {
  languageDropdown,
  languageDropdownItem,
  languageSelect,
  rotateArrow,
  dropdownOpen,
} from './LanguageSelect.module.scss';

const DROPDOWN_LIST_ID = 'languageSelectorDropdownList';
const BUTTON_ID = 'languageSelectorButton';

const dropdownItems = [
  {
    label: 'English',
    locale: 'en-CA',
  },
  {
    label: 'Français',
    locale: 'fr-CA',
  },
];

export const LanguageSelect = () => {
  const { supportedLocales, is404 } = usePageAttributesContext();
  const pathname = usePathname();
  const currentLanguageLabel = useTranslation('footer::language');
  const componentContext = useComponentContext();

  const linkRefs = useRef(
    Array(dropdownItems.length)
      .fill(null)
      .map(() => createRef())
  );

  const [focusIndex, setFocusIndex] = useState(0);

  const onClick = event => {
    event.stopPropagation();
    toggleOpen();
  };

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  useLayoutEffect(() => {
    const handleOnClick = () => {
      setOpen(false);
    };
    window.addEventListener('click', handleOnClick);
    return () => window.removeEventListener('click', handleOnClick);
  }, []);

  useEffect(() => {
    if (open) {
      linkRefs.current[focusIndex]?.current?.focus();
      trackAnalyticsEvent(
        EVENT_NAMES.LANGUAGE_SELECTOR_OPENED,
        {
          label: currentLanguageLabel,
        },
        componentContext
      );
    } else {
      setFocusIndex(0);
    }
  }, [open, focusIndex]);

  const handleKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown':
        setFocusIndex(last => (last + 1) % linkRefs.current.length);
        e.stopPropagation();
        e.preventDefault();
        break;
      case 'ArrowUp':
        setFocusIndex(last => {
          const next = (last - 1) % linkRefs.current.length;
          if (next < 0) return linkRefs.current.length - 1;
          return next;
        });
        e.stopPropagation();
        e.preventDefault();
        break;
      case 'Escape':
        toggleOpen();
        e.stopPropagation();
        e.preventDefault();
        break;
      case 'Tab':
        toggleOpen();
        break;
      default:
    }
  };

  return (
    <div className={languageSelect}>
      <CTAButton
        onClick={onClick}
        variant="secondary"
        size="xl"
        aria-haspopup="menu"
        aria-expanded={open}
        aria-label={useTranslation('footer::language_select::label')}
        aria-controls={DROPDOWN_LIST_ID}
        id={BUTTON_ID}
        tabIndex="0"
        icon={<CaretDown className={classNames(open && rotateArrow)} size="sm" />}
      >
        {currentLanguageLabel}
      </CTAButton>
      <ul
        className={classNames(languageDropdown, open && dropdownOpen)}
        tabIndex="-1"
        onKeyDown={handleKeyDown}
        role="menu"
        aria-labelledby={BUTTON_ID}
        id={DROPDOWN_LIST_ID}
      >
        {dropdownItems.map(({ label, locale }, idx) => {
          const isLocaleSupported = supportedLocales.some(supportedLocale => isEquivalentLocale(supportedLocale, locale));

          // go to home page if locale is not supported, or if path is 404
          const hrefPath = (isLocaleSupported && !is404) ? pathname : '/';

          return (
            <li key={locale} role="presentation" className={languageDropdownItem}>
              <SmartLink
                href={replaceLocaleInPathname(hrefPath, locale)}
                ref={linkRefs.current[idx]}
                eventData={{ name: EVENT_NAMES.LANGUAGE_SELECTOR_CLICKED, label }}
                onClick={() => setLanguageCookie(locale)}
                tabIndex="-1"
                role="menuitem"
              >
                {label}
              </SmartLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
